import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
  import { Button, Checkbox, Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CreateDrawer from "../CommonUi/CreateDrawer";
import AddProd from "../product/addProd";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import BigDrawer from "../Drawer/BigDrawer";
import AddSale from "./addSale";
import SerialNoModal from "../SerialNoModal/SerialNoModal";
import { validateProductSerialNos } from "../../utils/apis";
import SKUInput from "../SKUINput/SKUInput";
import { loadProduct } from "../../redux/rtk/features/product/productSlice";


export default function ProductAdd({
  form,
  productList,
  productLoading,
  totalCalculator,
  invoiceData,
  subTotal,
  data,
  productsKey = "saleInvoiceProduct",
  selectProductButtonRef,
  productsSerialNos,
  setProductsSerialNos
}) {
  const { Option } = Select;
  const { list: vatTaxList, loading: vatTaxLoading } = useSelector(
		(state) => state.vatTax
	);

  const [openAddProd, setOpenAddProd] = useState(false);

  const [openSerialNoModal, setOpenSerialNoModal] = useState(false)
  const [productIndex, setProductIndex] = useState(-1);
  const [serialNosInitialValue, setSerialNosInitialValue] = useState();
  const [createdProductId, setCreatedProductId] = useState("");

  const productSettings = useSelector((state) => state.accounts.productSettings);
  
  const buttonRef = useRef(null)
  const addFunctionRef = useRef(null);
  const removeFunctionRef = useRef(null);

  const dispatch = useDispatch();
  // const [vatPriceId, setVatPriceId] = useState(0);
  // const [gstId, setgstId] = useState();

  const handleSetInitial = ({ product, index, add, name, remove }) => {
    const productArray = form.getFieldValue(productsKey);

    // const existingProduct = productArray.find((val) => val.id === product);

    const findProduct = productList.find((pro) => pro.id === product);

    // form.setFieldValue('vatPrice', findProduct?.productVatId)
    // form.setFieldValue('vatPrice', vatPriceId)
    
    if (findProduct.productQuantity === 0) {
      toast.warning("Product is out of stock");
    }

    let updatingExistingProductQuanity = false;
    let existingProductIndex = -1;
    let newArray = productArray.map((eachProduct, productIndex) => {
      if (productIndex === index && eachProduct && !updatingExistingProductQuanity) {
        return {
          ...eachProduct,
          productQuantity: findProduct.enableSerialNo ? 0 : 1,
          productSalePrice: findProduct.productSalePrice,
          productMrpPrice: findProduct.productMrpPrice,
          // productVat: !!findProduct.vatPrice ? findProduct.vatPrice : 0,
          unitMeasurement: findProduct?.unitMeasurement || 0,
          discount: findProduct?.discount || 0,
          discount_type: findProduct?.discount_type || "amount",
          id: findProduct?.id,
          vatId: findProduct?.productVatId,
          vatPrice: findProduct?.productVatId ? vatTaxList?.find((v) => v?.id == findProduct?.productVatId)?.percentage : 0, //temporary value
          // vatPrice: !!findProduct?.id ? findProduct?.productVatId : 0,
          // vatPrice : findProduct?.productVat && findProduct?.productVat?.title + '@' + findProduct?.productVat?.percentage + '%', 
          inclusiveGst: findProduct.inclusiveGst,
          product: findProduct,
          description: findProduct.description,
          hsn_code: findProduct.hsn_code
        };
      } else if (eachProduct?.productId === product) {
        updatingExistingProductQuanity = true;
        if (index === productIndex) {
          remove(name);
          return {};
        }
        existingProductIndex = productIndex;
        return {
          ...eachProduct,
          productQuantity: findProduct.enableSerialNo ? _.get(eachProduct, "productQuantity", 0) : _.get(eachProduct, "productQuantity", 0) + 1,
        }
      } else {
        return eachProduct;
      }
    });

    newArray = _.filter(newArray, each => !_.isEmpty(each));

    // const sampleVatPrice = findProduct?.productVatId

    // setVatPriceId(findProduct?.productVatId)

    // const vatFields = form.getFieldValue("vatId");

    // const vatPricePercent = findProduct?.productVat?.percentage

		// const totalVatArray =
		// 	vatFields?.map((id) => {
		// 		return vatTaxList.find((item) => id === item.id)?.percentage;
		// 	}) || [];

    form.setFieldsValue({  [productsKey]: newArray, });
    totalCalculator(index);
    add();

    if (findProduct.enableSerialNo) {
      handleSerialNoFieldFocus(null, existingProductIndex !== -1 ? existingProductIndex : index, true);
    }
  };

  const serialNoEnabledFlags = useMemo(() => {
    const productArray = form.getFieldValue("saleInvoiceProduct");
    return _.map(productArray, product => {
      const productDetails = _.find(productList, { id: product?.productId })
      return _.get(productDetails, "enableSerialNo", false);
    })
  }, [form.getFieldValue("saleInvoiceProduct")])

  const handleSerialNoFieldFocus = (e, index, enabledFlag) => {
    if ((serialNoEnabledFlags[index] || enabledFlag) && !_.isEmpty(data)) {
      message.warning("The product has serial no's, so quantity can't be updated.")
      return;
    }
    if (serialNoEnabledFlags[index] || enabledFlag) {
      e?.target?.blur();
      const productArray = form.getFieldValue("saleInvoiceProduct");
      const prod = productArray[index];
      setSerialNosInitialValue(_.get(productsSerialNos, prod.productId))
      setProductIndex(index);
      setOpenSerialNoModal(true);
    }
  }

  const handleSetSerialNos = async (serialNos) => {
    const productArray = form.getFieldValue("saleInvoiceProduct");
    const prod = productArray[productIndex];

    if (prod) {
      const payloadToValidateSerialNo = {
        productId: prod.productId,
        serialNos,
        type: "sale"
      }
      const response = await validateProductSerialNos(payloadToValidateSerialNo);
      if (response.status == "success") {
        setProductsSerialNos(prev => ({
          ...prev,
          [prod.productId]: serialNos
        }))
        productArray[productIndex].productQuantity = _.size(serialNos)
        form.setFieldValue("saleInvoiceProduct", productArray);
      } else {
        message.error(response.message);
        return;
      }
    }

    setProductIndex(-1);
    setOpenSerialNoModal(false);
  }

  useEffect(() => {
    if (data) {
      const processedData = _.get(data, productsKey, [])?.map((v) => (
        {
          ...v,
          vatPrice: v?.productGST,
          description: v?.description,
          hsn_code: v?.hsn_code
        }
      ))
      form.setFieldValue(productsKey, processedData)
    }
  }, [data]);

  useLayoutEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.click();
      if (selectProductButtonRef) {
        selectProductButtonRef.current = buttonRef.current
      }
    }
  }, []);

  const isDisabled = (index) => {
    const productArray = form.getFieldValue(productsKey);
    const findProduct = _.get(productArray, index);
    return !_.get(findProduct, "product.status", true);
  }

  useEffect(() => {
    // To select newly created product in the products list
    if (createdProductId) {
      const product = _.find(productList, { id: createdProductId });
      if (!_.isEmpty(product)) {
        setCreatedProductId("");
        const productArray = form.getFieldValue(productsKey);
        const emptyValIndex = _.findIndex(productArray, val => _.isEmpty(val));
        if (emptyValIndex == -1) {
          productArray.push({ productId: createdProductId });
        } else {
          productArray[emptyValIndex] = { productId: createdProductId };
        }
        form.setFieldValue(productsKey, productArray);
        handleSetInitial({
          product: createdProductId,
          index: productArray.length - 1,
          add: addFunctionRef.current,
          remove: removeFunctionRef.current,
          name: "productId",
        });
      }
    }
  }, [createdProductId, productList]);
  
  const handleAddProdSuccess = (productId) => {
    setOpenAddProd(false);
    setCreatedProductId(productId)
    dispatch(loadProduct({ page: 1, count: 100000 }));
  }

  const handleDescriptionChange = (e, index) => {
    const { value } = e.target;
    const productArray = form.getFieldValue(productsKey);
    const updatedFields = [...productArray];
    updatedFields[index].description = value;
    form.setFieldsValue({ [`${productsKey}[${index}].description`]: value });
  };
  const handleHsncodeChange = (e, index) => {
    const { value } = e.target;
    const productArray = form.getFieldValue(productsKey);
    const updatedFields = [...productArray];
    updatedFields[index].hsn_code = value;
    form.setFieldsValue({ [`${productsKey}[${index}].hsn_code`]: value });
  };
  
  return (
    <>
      <Row className='flex justify-between !flex-nowrap'>
        <Col style={{ minWidth: "30px", width: "30px" }}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            S.No
          </div>
        </Col>
        <Col span={productSettings?.showPurchasePrice ? 4 : 5}>
          <div className='lg:ml-2 font-weight-bold md:text-base sm:text-sm'>
            Product
          </div>
        </Col>
        {invoiceData?.show_description_pdf_invoice === true && (
             <Col span={productSettings?.showPurchasePrice ? 2 : 3}>
               <div className='lg:ml-2 font-weight-bold md:text-base sm:text-sm'>
                 Description
               </div>
             </Col>
        )}
             {invoiceData?.show_Hsn_Code === true && (
              <Col style={{ minWidth: "95px", width: "95px" }}>
               <div className='lg:ml-2 font-weight-bold md:text-base sm:text-sm'>
                 HSN
               </div>
             </Col>
        )}
        {
          invoiceData?.showSkuOnSale &&
          <Col span={2}>
            <div className='font-weight-bold md:text-base sm:text-sm'>
              SKU
            </div>
          </Col>
        }
        {/* <Col span={1}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            U.M.
          </div>
        </Col> */}
        <Col span={3} className="flex gap-2">
          <div className='w-2/5 max-lg:w-24 font-weight-bold md:text-base sm:text-sm'>
            Qty
          </div>
       
          <div className='w-3/5 font-weight-bold md:text-base sm:text-sm'>
            MRP
          </div>
        </Col>
        <Col span={1}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            Dis
          </div>
        </Col>
        {
          productSettings?.showPurchasePrice &&
          <Col span={2}>
            <div className='font-weight-bold md:text-base sm:text-sm'>
              Purchase Price
            </div>
          </Col>
        }
        <Col span={2}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            Sale Price
          </div>
        </Col>
        <Col span={2}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            GST
          </div>
        </Col>
        <Col span={1}>
          <div className='font-weight-bold md:text-base sm:text-sm'>
            IncGST
          </div>
        </Col>
        <Col span={2}>
          <div className='lg:ml-1 font-weight-bold md:text-base sm:text-sm'>
            Total
          </div>
        </Col>
        <Col span={1}>
          <div className='md:text-base sm:text-sm'>Del</div>
        </Col>
      </Row>

      <hr style={{ backgroundColor: "black", marginTop: "0.5rem" }} />

      <Form.List
        name={productsKey}
        // initialValue={processedData}
        rules={[
          {
            required: true,
            message: "Product is required",
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            {(addFunctionRef.current = add, removeFunctionRef.current = remove)}
            <div className='max-h-[160px] overflow-y-auto overflow-x-hidden mt-1 gap-5'>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row className='flex justify-between !flex-nowrap items-center' gutter={[5]} key={key}>
                  <Col style={{ minWidth: "30px", width: "30px" }}>{index + 1}</Col>
                  <Col span={productSettings?.showPurchasePrice ? 4 : 5}>
                    <Form.Item
                      {...restField}
                      name={[name, "productId"]}
                      rules={[
                        {
                          required: !fields?.length ? true : false,
                          message: "Product is required",
                        },
                      ]}
                    >
                      <Select
                        disabled={isDisabled(index)}
                        placeholder='Select Product'
                        showSearch
                        autoFocus={invoiceData?.showSkuOnSale ? false : true}
                        loading={productLoading}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={(product) => {
                          isDisabled(product)
                          handleSetInitial({ product, index, add, remove, name });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && fields.length - 1 >= index) {
                            add(); // Add a new field when Enter is pressed
                          }
                          if (e.key == 'Delete'){
                            remove(name);
                            totalCalculator(index);
                          }
                        }}
                      >
                        {productList?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {invoiceData?.show_description_pdf_invoice === true && (
                    <Col span={productSettings?.showPurchasePrice ? 2 : 3}>
                    <Form.Item
                     {...restField}
                     name={[name, "description"]}
                     rules={[
                       {
                         required: !fields?.length ? true : false,
                         message: "Product Description is required",
                       },
                     ]}
                    >
                      <Input
							          className=''
							          placeholder='Product Description'
                        onChange={(e) => handleDescriptionChange(e, index)}
                        disabled={isDisabled(index)} 
                        />
                    </Form.Item>
                  </Col>
                  )} 
                  {invoiceData?.show_Hsn_Code === true && (
                    <Col style={{ minWidth: "95px", width: "95px" }}>
                    <Form.Item
                     {...restField}
                     name={[name, "hsn_code"]}
                     rules={[
                       {
                         required: !fields?.length ? true : false,
                         message: "Product Hsn_code is required",
                       },
                     ]}
                    >
                      <Input
							          className=''
							          placeholder='Hsn_code'
                        onChange={(e) => handleHsncodeChange(e, index)}
                        disabled={isDisabled(index)} 
                        />
                    </Form.Item>
                  </Col>
                  )} 
                  {
                    invoiceData?.showSkuOnSale &&
                    <Col span={2}>
                      <SKUInput
                        add={add}
                        handleSetInitial={handleSetInitial}
                        index={index}
                        name={name}
                        productList={productList}
                        productLoading={productLoading}
                        remove={remove}
                        totalCalculator={totalCalculator}
                        isDisabled={isDisabled}
                        key={index}
                        fields={fields}
                        restField={restField}
                        form={form}
                        productsKey={productsKey}
                        openSerialNoModal={openSerialNoModal}
                      />
                    </Col>
                  }
                  <Col span={3} className="flex gap-2">
                    <Form.Item className="w-2/5 max-lg:w-24"
                      {...restField}
                      name={[name, "productQuantity"]}
                      rules={[
                        {
                          required: !fields?.length ? true : false,
                          message: "quantity is required",
                        },
                        {
                          validator: (_, value) =>
                            value == 0
                              ? Promise.reject(new Error(''))
                              : Promise.resolve(),
                        }
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        size={"small"}
                        placeholder='Quantity'
                        // max={subTotal[index]?.productQuantity || null}
                        // min={0}
                        onChange={() => totalCalculator(index)}
                        disabled={isDisabled(index) || _.isEmpty(form.getFieldValue(productsKey)[index])}
                        onFocus={(e) => handleSerialNoFieldFocus(e, index)}
                        readOnly={serialNoEnabledFlags[index]}
                      />
                    </Form.Item>
                  
                    <div className='w-3/5 mt-2 font-weight-bold md:text-base'>
                    {subTotal[index]?.productMrpPrice}
                    </div>
                  </Col>   
                  <Col span={1}>
                    <div className=' font-weight-bold md:text-base'>
                    {subTotal[index]?.discount_type == "amount" ? "₹" : " "}{subTotal[index]?.discount}{subTotal[index]?.discount_type == "percentage" ? "%" : " "}
                    </div>
                  </Col>
                  {
                    productSettings?.showPurchasePrice && <Col span={2}>
                      <div className=' font-weight-bold md:text-base'>
                        {subTotal[index]?.productPurchasePrice}
                      </div>
                    </Col>
                  }
                  <Col span={2}>
                    <Form.Item
                      {...restField}
                      name={[name, "productSalePrice"]}
                      rules={[
                        {
                          required: !fields?.length ? true : false,
                          message: "Price is required",
                        },
                      ]}
                    >
                      <InputNumber
                        size='small'
                        style={{ width: "100%" }}
                        placeholder='Sale Price'
                        onChange={() => totalCalculator(index)}
                        disabled={isDisabled(index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {/* Gst section */}
                    <Form.Item
                      {...restField}
                      name={[name, "vatPrice"]}
                      // name='vatID'
                      rules={[
                        {
                          required: !fields?.length ? true : false,
                          message: "GST is required",
                        },
                      ]}
                      initialValues={{
                        vatId: [],
                      }}
                    >
                      <Select
                        placeholder='Select GST type'
                        showArrow
                        loading={vatTaxLoading}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children.toLowerCase() === input.toLowerCase()
                        }
                        onChange={() => totalCalculator()}
                        onKeyDown={(e) => {
                          if (e.key == 'Delete'){
                            remove(name);
                            totalCalculator(index);
                          }
                        }}
                        disabled={isDisabled(index)}
                      >
                        {vatTaxList?.map((item) => (
										      <Option key={item.id} value={item.percentage}>
										      	{item.title}
										      	<span className='italic'>@{item.percentage}%</span>
										      </Option>
									      ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Form.Item
                      {...restField}
                      name={[name, "inclusiveGst"]}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isDisabled(index)} name="inclusiveGst" onChange={() => totalCalculator(index)} className={"styles.InclusiveGSTItem"}></Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={2}>
                    <div className='lg:ml-1 font-weight-bold md:text-base'>
                      {subTotal[index]?.subPrice?.toFixed(2) || 0}
                    </div>
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <button
                        shape='circle'
                        className='flex justify-end items-end bg-red-600 text-white p-2 rounded-md lg:ml0.5'
                        onClick={() => {
                          remove(name);
                          totalCalculator(index);
                        }}
                      >
                        <DeleteOutlined className='' />
                      </button>
                    </Form.Item>
                  </Col>
                </Row>
              )
              )}
            </div>
            <div className="flex items-center gap-2">
              <Form.Item className="!my-2 max-sm:w-40 w-full">
                <Button
                  type='dashed'
                  ref={buttonRef}
                  onClick={() => add()}
                  block
                  // icon={<PlusOutlined />}
                >
                  Select Product
                </Button>
              </Form.Item>
              <BigDrawer
                  permission={"create-product"} 
                  title={" Product"}
                  btnTitle={"Create Product"}
                  openFlag={openAddProd}
                  setOpenFlag={setOpenAddProd}
                  children={<AddProd hideSaveAndCreate={true} onSuccess={handleAddProdSuccess} drawer={true} />}
                />
              <CreateDrawer
                 permission={"create-saleInvoice"}
                 title={"Sale"}
                 width={92}
               >
                 <AddSale />
               </CreateDrawer>
              {/* <CreateDrawer permission={"create-product"} title={"Create Product"} setOpenFlag={setOpenAddProd}>
                   <AddProd onSuccess={handleAddProdSuccess} drawer={true}/>
              </CreateDrawer> */}
            </div>
          </>
        )}
      </Form.List>
      {openSerialNoModal && <SerialNoModal
        handleSetSerialNos={handleSetSerialNos}
        openModal={openSerialNoModal}
        setOpenModal={setOpenSerialNoModal}
        productsSerialNos={productsSerialNos}
        productIndex={productIndex}
        parentForm={form}
        serialNosInitialValue={serialNosInitialValue}
      />}

    </>
  );
}
