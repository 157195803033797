import axios from "axios";
import _ from "lodash";

export const validateProductSerialNos = async (payload) => {
    try {
        const response = await axios.post("product/validate-serial-nos", payload);

        return {
            result: response.data,
            status: "success",
        };

    } catch (err) {
        return {
            status: "failed",
            message: _.get(err, "response.data.message", "")
        };
    }
}