// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.on-screen-keyboard {
    position: sticky;
    width: -webkit-fill-available;
    right: 0;
    bottom: -18px;
}

.on-screen-keyboard .outside-click-handler {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.on-screen-keyboard .react-simple-keyboard {
    z-index: 1;
    position: relative;
}

.on-screen-keyboard .hg-standardBtn,
.on-screen-keyboard .simple-keyboard.myTheme1 .hg-button {
    height: 65px !important;
}

/*
  Theme: myTheme1
*/
.simple-keyboard.myTheme1 {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.simple-keyboard.myTheme1 .hg-button {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
}

.simple-keyboard.myTheme1 .hg-button:active {
    background: #1c4995;
    color: white;
}

#root .simple-keyboard.myTheme1+.simple-keyboard-preview {
    background: #1c4995;
}`, "",{"version":3,"sources":["webpack://./src/components/OnScreenKeyboard/OnScreenKeyboard.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,6BAA6B;IAC7B,QAAQ;IACR,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;;IAEI,uBAAuB;AAC3B;;AAEA;;CAEC;AACD;IACI,oCAAoC;IACpC,gBAAgB;IAChB,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".on-screen-keyboard {\n    position: sticky;\n    width: -webkit-fill-available;\n    right: 0;\n    bottom: -18px;\n}\n\n.on-screen-keyboard .outside-click-handler {\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n}\n\n.on-screen-keyboard .react-simple-keyboard {\n    z-index: 1;\n    position: relative;\n}\n\n.on-screen-keyboard .hg-standardBtn,\n.on-screen-keyboard .simple-keyboard.myTheme1 .hg-button {\n    height: 65px !important;\n}\n\n/*\n  Theme: myTheme1\n*/\n.simple-keyboard.myTheme1 {\n    background-color: rgba(0, 0, 0, 0.8);\n    border-radius: 0;\n    border-bottom-right-radius: 5px;\n    border-bottom-left-radius: 5px;\n}\n\n.simple-keyboard.myTheme1 .hg-button {\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: rgba(0, 0, 0, 0.5);\n    color: white;\n}\n\n.simple-keyboard.myTheme1 .hg-button:active {\n    background: #1c4995;\n    color: white;\n}\n\n#root .simple-keyboard.myTheme1+.simple-keyboard-preview {\n    background: #1c4995;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
