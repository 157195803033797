import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isString } from 'lodash';
import moment from 'moment';
import getPermissions from './getPermissions';
import _ from "lodash";

// remove all falsy property from  object
export function removeFalsyProperties(obj) {
  const newObj = {};
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop) && obj[prop]) {
      newObj[prop] = obj[prop];
    }
  }
  return newObj;
}

export function stringShorter(str, length) {
  return str?.length > length ? str.slice(0, length) + "..." : str;
}

export function DECIMAL_NUMBER(number, decimalDigits = 2) {
  if (number % 1 == 0) {
    return number;
  }
  return Number(Number(number).toFixed(decimalDigits));
}

export const getBlobFromHtml = (componentRef) => {

  return html2canvas(componentRef.current, {
    height: componentRef.current.offsetHeight + 40,
    width: componentRef.current.offsetWidth + 40,
    useCORS: true
  }
  ).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'px', 'a4', false);
    // pdf.addImage(imgData, 'PNG', 50, 0, 380, 650, undefined, false);
    pdf.addImage(imgData, 'PNG', 100, 0, 280, 600, undefined, "FAST");
    // pdf.save("invoice");
    const pdfBlob = pdf.output('blob');
    return pdfBlob;
  });
};

export function isValidUrl(string) {
  let url;
  try {
    url = new URL(string);

  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function clearLocalStorageExcept(includeName) {
  const keysToKeep = [];
  // Iterate over all items in local storage and collect the keys to keep
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.includes(includeName)) {
      keysToKeep.push(key);
    }
  }
  // Iterate over all items in local storage again to remove those that are not to be kept
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  }
}

export const getMomentObj = (date) => {
  if (isString(date)) {
    return moment(date);
  } else {
    return moment(date?.toString?.());
  }
}

export const hasPermission = (item) => {
  const permissions = getPermissions();
  return permissions?.includes(item ? item : "");
};

export const getSerialNos = (d, data) => {
  let serialNos = _.map(_.get(d, "productSerialNos", []), "serialNo");
  if (_.isEmpty(serialNos)) {
    serialNos = _.get(data, `productsSerialNos.${d.productId}`)
  }
  return _.join(serialNos, ", ")
}