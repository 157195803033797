import { Form, message, Popover, Select } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import Html5QrcodePlugin from "../QRReaderComponent/QRReaderComponent";
import _ from "lodash";

function SKUInput({
    productLoading,
    index,
    add,
    remove,
    name,
    totalCalculator,
    productList,
    handleSetInitial,
    isDisabled = () => false,
    restField,
    fields,
    form,
    productsKey,
    openSerialNoModal
}) {
    const [showScanner, setShowScanner] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const handleFocus = () => {
        isMobile && !openSerialNoModal && setTimeout(() => setShowScanner(true), 1000);
    };

    const isMobile = useMemo(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
        return isMobileDevice;
    }, []);

    useEffect(() => {
        if (openSerialNoModal && showScanner) {
            setShowScanner(false);
        }
    }, [openSerialNoModal]);

    const updateValue = (value)=>{
        setSearchValue(value);
        const findProduct = _.find(productList, { sku: value });
        const products = form.getFieldValue(productsKey);
        _.set(products, `${index}.productId`, findProduct.id);
        form.setFieldValue(productsKey, products);
        handleSetInitial({ product: findProduct.id, index, add, remove, name });
    }

    const handleScannerCallback = (value) => {
        setShowScanner(false);
        updateValue(value);
    }

    return (
        <div>
            <Popover destroyTooltipOnHide open={showScanner} content={<div id="reader" style={{ width: '300px', zIndex: 10 }}>
                {
                    showScanner &&
                    <Html5QrcodePlugin
                        fps={10}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={handleScannerCallback}
                    />
                }
            </div>}>
                <Form.Item
                    {...restField}
                    name={[name, "productId"]}
                    rules={[
                        {
                            required: !fields?.length ? true : false,
                            message: "SKU is required",
                        },
                    ]}
                >
                    <Select
                        placeholder='Select SKU'
                        showSearch
                        autoFocus={true}
                        loading={productLoading}
                        optionFilterProp='children'
                        searchValue={searchValue}
                        onSearch={(e) => setSearchValue(e)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase() === input.toLowerCase()
                        }
                        onChange={(product) => {
                            setShowScanner(false);
                            handleSetInitial({ product, index, add, remove, name });
                        }}
                        onKeyDown={(e) => {
                            // if (e.key === "Enter" && fields.length - 1 >= index) {
                            //   add(); // Add a new field when Enter is pressed
                            // }
                            if (e.key == 'Delete') {
                                remove(name);
                                totalCalculator(index);
                            }
                        }}
                        disabled={isDisabled(index)}
                        onFocus={handleFocus}
                    >
                        {productList?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.sku}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Popover>
        </div>
    )
}

export default SKUInput;