import React, { useRef, useState } from "react";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import "./OnScreenKeyboard.css"
import _ from "lodash";
import { useSelector } from "react-redux";

const useOnScreenKeyboard = (props) => {

    const [showScreenKeyboard, setShowScreenKeyboard] = useState(false);
    const [keyboardOptions, setKeyboardOptions] = useState({ layout: "default", name: "" });
    const updateHandlerRef = useRef(null);
    const valueRef = useRef();

    const invoiceData = useSelector((state) => state.accounts.appSettings);

    const handleInputChange = (value) => {
        let formattedValue = keyboardOptions.layout == "numbers" ? _.toNumber(value) : value;
        if (updateHandlerRef.current) {
            updateHandlerRef.current.handler(keyboardOptions.name, formattedValue);
        }
    }

    const handleInputFocus = ({ name, updateHandler, layout = "default" }) => {
        if (invoiceData?.onScreenKeyboardEnabled) {
            const input = document.querySelector(`[id$=${name}]`)
            valueRef.current = input?.getAttribute("value")

            setKeyboardOptions({ layout, name })
            updateHandlerRef.current = {
                handler: updateHandler
            }
            setShowScreenKeyboard(true);
        }
    }

    // Handle key press events
    const handleKeyPress = (button) => {
        if (button === "{enter}") {
            setShowScreenKeyboard(false);  // Close the keyboard when Enter is pressed
        } else if (button === "{lock}") {
            handleCapsLock()
        } else if (button === "{bksp}") {
            if (valueRef.current) {
                const updatedValue = _.join(_.slice(valueRef.current, 0, -1), "");
                valueRef.current = updatedValue;
                handleInputChange(updatedValue);
            }
        }
    };

    // Toggle Caps Lock
    const handleCapsLock = () => {
        setKeyboardOptions((prevOptions) => ({
            ...prevOptions,
            layout: prevOptions.layout == "default" ? "shift" : "default"
        }));
    };

    const KeyBoard = () => {
        return (
            (showScreenKeyboard && invoiceData?.onScreenKeyboardEnabled) ?
                <div className="on-screen-keyboard">
                    <div className="outside-click-handler" onClick={() => setShowScreenKeyboard(false)}></div>
                    <Keyboard
                        onChange={handleInputChange}
                        theme="hg-theme-default myTheme1"
                        inputName={keyboardOptions.name}
                        onKeyPress={handleKeyPress}
                        layoutName={keyboardOptions.layout}
                        layout={{
                            'default': [
                                '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                                'q w e r t y u i o p [ ] \\',
                                'a s d f g h j k l ; \' {enter}',
                                '{lock} z x c v b n m , . /',
                                '.com @ {space}'
                            ],
                            'shift': [
                                '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                                'Q W E R T Y U I O P { } |',
                                'A S D F G H J K L : \' {enter}',
                                '{lock} Z X C V B N M < > ?',
                                '.com @ {space}'
                            ],
                            'numbers': [
                                '1 2 3',
                                '4 5 6',
                                '7 8 9',
                                '0 {bksp} {enter}'
                            ]
                        }}
                        display={{
                            "{enter}": "Enter ↵",
                            "{bksp}": "⌫",
                            "{space}": "Space",
                            "{shift}": "Shift",
                            "{lock}": "Caps lock",
                            "{tab}": "Tab",
                        }}
                    />
                </div> : <></>)
    }
    return { KeyBoard, handleInputFocus, showScreenKeyboard }
}

export default useOnScreenKeyboard;